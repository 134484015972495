import { FormSubscript } from "@/components/forms/form-subscribe";
import Link from "next/link";

export default function Footer() {
	return (
		<section className="">
			<p className="container justify-center flex text-3xl font-bold">Nossos parceiros</p>
			<div
				className="flex h-[100px] bg-center relative mt-"
				style={{
					backgroundImage: "url('/Group 19.svg')",
					backgroundRepeat: "repeat-x",
					width: "100%",
				}}
			>
			</div>
			<footer className="bg-[#063b89]">
				<div className="container pt-4 flex flex-col gap-[100px]">
					<div className="grid grid-cols-1 items-center gap-4 md:grid-cols-2 md:grid-rows-2">
						<h3 className="text-xl font-bold text-white">Receber novidades do mercado imobiliário por email</h3>
						<FormSubscript />
					</div>
					<nav className="grid grid-cols-4 gap-4 max-md:grid-cols-1">
						<div>
							<p className="mb-4 text-sm font-bold text-white/40">
								Empresa
							</p>
							<ul className="flex flex-col gap-3 max-md:flex-row max-md:flex-wrap">
								<li className="text-base text-white">
									<Link
										className="cursor-pointer hover:brightness-110"
										href="/lawyer/about"
									>
										Sobre nós
									</Link>
								</li>
							</ul>
						</div>
						<div>
							<p className="mb-4 text-sm font-bold text-white/40">
								Recursos
							</p>
							<ul className="flex flex-col gap-3 max-md:flex-row max-md:flex-wrap">
								<li className="text-base text-white">
									<Link
										className="cursor-pointer hover:brightness-110"
										href="/lawyer/blog"
									>
										Blog
									</Link>
								</li>
								<li className="text-base text-white">
									<Link
										className="cursor-pointer hover:brightness-110"
										href="/lawyer/faq"
									>
										Dúvidas frequentes
									</Link>
								</li>
							</ul>
						</div>
						<div>
							<p className="mb-4 text-sm font-bold text-white/40">
								Social
							</p>
							<ul className="flex flex-col gap-3 max-md:flex-row max-md:flex-wrap">
								<li className="text-base text-white">
									<Link
										className="cursor-pointer hover:brightness-110"
										href="https://www.instagram.com/minbimoveis/"
										target="_blank"
										rel="noopener noreferrer"
									>
										Instagram
									</Link>
								</li>
								<li className="text-base text-white">
									<Link
										className="cursor-pointer hover:brightness-110"
										href="https://www.facebook.com/minbimoveis"
										target="_blank"
										rel="noopener noreferrer"
									>
										Facebook
									</Link>
								</li>
							</ul>
						</div>
						<div>
							<p className="mb-4 text-sm font-bold text-white/40">Legal</p>
							<ul className="flex flex-col gap-3 max-md:flex-row max-md:flex-wrap">
								<li className="text-base text-white">
									<Link
										className="cursor-pointer hover:brightness-110"
										href="/lawyer/terms"
									>
										Termos
									</Link>
								</li>
								<li className="text-base text-white">
									<Link
										className="cursor-pointer hover:brightness-110"
										href="/lawyer/contact"
									>
										Contato
									</Link>
								</li>
								<li className="text-base text-white">
									<Link
										className="cursor-pointer hover:brightness-110"
										href="/lawyer/policy-privacy"
									>
										Politica de Privacidade
									</Link>
								</li>
							</ul>
						</div>
					</nav>
				</div>
				<div className="flex justify-center py-6">
					<p className="text-white/40">
						<strong className="text-white">MINB</strong> — Todos os direitos
						reservado
					</p>
				</div>
			</footer>
		</section>
	);
}
